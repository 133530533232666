import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
// import { FaBars, FaChevronDown, FaChevronRight, FaTimes } from "react-icons/fa";
// import { useState } from "react";

const Header = () => {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // const toggleMobileMenu = () => {
  //   setMobileMenuOpen(!mobileMenuOpen);
  // };

  return (
    <header className="pt-[13px] xl:pt-0 px-[25px] h-[60px] xl:h-[55px] flex items-start justify-between relative ww">
      <Link to="/" className="xl:pt-[13px]">
        <img src={logo} alt="logo" className="h-[30px]" />
      </Link>
      {/* <button onClick={toggleMobileMenu} className="xl:hidden">
        {mobileMenuOpen ? (
          <FaTimes className="font-black text-[2em]" />
        ) : (
          <FaBars className="font-black text-[2em]" />
        )}
      </button>

      {mobileMenuOpen && (
        <div className="mh absolute top-[50px] left-0 z-[1000] w-full bg-white">
          <ul>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <Link to="/">Home</Link>
              <FaChevronRight className="absolute right-10" />
            </li>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <button>About</button>
              <FaChevronRight className="absolute right-10" />
            </li>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <button>What is MPI®?</button>
              <FaChevronRight className="absolute right-10" />
            </li>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <button>Investor/Business Solutions</button>
              <FaChevronRight className="absolute right-10" />
            </li>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <button>Rollover Solutions</button>
              <FaChevronRight className="absolute right-10" />
            </li>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <button>Additional Solutions</button>
              <FaChevronRight className="absolute right-10" />
            </li>
            <li className="p-2.5 pl-[30px] border-b border-b-grey relative flex items-center">
              <button>Contact Us</button>
              <FaChevronRight className="absolute right-10" />
            </li>
          </ul>
        </div>
      )} */}

      <div>
        {/* <div className="flex">
          <Link to="/" className="flex items-center py-[15px] px-2.5">
            Home <FaChevronDown className="ml-[15px]" />
          </Link>
          <button className="flex items-center py-[15px] px-2.5">
            About <FaChevronDown className="ml-[15px]" />
          </button>
          <button className="flex items-center py-[15px] px-2.5">
            What is MPI®? <FaChevronDown className="ml-[15px]" />
          </button>
          <button className="flex items-center py-[15px] px-2.5">
            Investor/Business Solutions <FaChevronDown className="ml-[15px]" />
          </button>
          <button className="flex items-center py-[15px] px-2.5">
            Rollover Solutions <FaChevronDown className="ml-[15px]" />
          </button>
          <button className="flex items-center py-[15px] px-2.5">
            Additional Solutions <FaChevronDown className="ml-[15px]" />
          </button>
        </div> */}

        <a
          className="flex items-center py-[15px]"
          href="https://dashboard.securedcompoundinterest.com"
        >
          Dashboard
        </a>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import Header from "../components/Header";
import bg from "../assets/Mobile_BG.png";
import explained from "../assets/cr_website-icons-06.png";
import testimonials from "../assets/cr_website-icons-07.png";
import poster from "../assets/poster.png";
import book from "../assets/Everyone-Ends-Up-Poor-Book-Cover.png";
import booktwo from "../assets/The-Lost-Science-of-Compound-Interest-Cover-Edited.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import google from "../assets/google.png";
import Footer from "../components/Footer";
import { FaStar } from "react-icons/fa6";
import avatar from "../assets/avatar.png";

const Home = () => {
  return (
    <div className="font-myriad">
      <div className="h-[30px] bg-yellow" />
      <Header />
      <section className="p-2.5 xl:p-0 xl:pt-[30px] text-2xl font-bold text-center leading-10 xl:bg-hero xl:min-h-[800px] bgg">
        <h1 className="xl:text-[36px]">Home of the</h1>
        <h1 className="text-[28px] md:text-[34px] xl:text-[50px]">
          MPI® Secure Compound <br className="xl:hidden" />
          Interest Account®
        </h1>

        <div className="py-[15px]">
          <div className="w-full md:w-[515px] xl:w-[1100px] h-[1px] bg-dark mx-auto" />
        </div>

        <img src={bg} alt="bg" className="xl:hidden" />

        <div className="hidden xl:flex xl:items-center">
          <div className="p-2.5 w-1/2 absolute top-1/2 flex flex-col items-center justify-center">
            <h5 className="max-w-[512px] font-normal text-[25px]">
              Increase your retirement by up to{" "}
              <b>
                <u>3X</u>
              </b>{" "}
              over the 401(k), Roth IRA and Index Funds
            </h5>
            <a
              className="w-[250px] rounded-lg text-[20px] bg-yellow font-normal border-[2px] border-black mt-[30px]"
              href="https://dashboard.securedcompoundinterest.com"
            >
              Start Today
            </a>
          </div>
        </div>

        <div className="block xl:hidden mt-2.5">
          <h5 className="font-normal text-[18px] leading-tight">
            Increase your retirement by up to{" "}
            <b>
              <u>3X</u>
            </b>{" "}
            over the 401(k), Roth IRA and Index Funds
          </h5>
          <button className="w-[250px] rounded-lg text-[16px] bg-yellow font-normal border-[2px] border-black mt-3">
            <a href="https://dashboard.securedcompoundinterest.com">
              Start Today
            </a>
          </button>
        </div>
      </section>

      <section className="p-2.5 xl:hidden">
        <div className="flex items-center min-h-[150px] border-b border-b-border">
          <img
            src={explained}
            alt="explained"
            className="max-w-[35%] mx-auto"
          />
          <div className="w-1/2">
            <p className="mb-[5px] font-bold">
              MPI® Secure Compound
              <br />
              Interest Explained
            </p>
            <a href="#mpi-explained">Learn More</a>
          </div>
        </div>
        <div className="flex items-center min-h-[150px]">
          <img
            src={testimonials}
            alt="testimonials"
            className="max-w-[35%] mx-auto"
          />
          <div className="w-1/2">
            <p className="mb-[5px] font-bold">
              MPI® Secure Compound
              <br />
              Interest Testimonials
            </p>
            <a href="#testimonials">Learn More</a>
          </div>
        </div>
      </section>

      <section className="hidden xl:flex xl:justify-center">
        <div className="border border-border text-center rounded-[10px] w-[320px] min-h-[450px] bg-white pt-5 mr-2.5 flex flex-col items-center">
          <img
            src={explained}
            alt="explained"
            className="max-w-[60%] mx-auto"
          />
          <p className="mt-10 mb-[14.4px] text-2xl">
            MPI® Secure
            <br />
            Compound Interest
            <br />
            <span className="text-[28px] font-bold">Explained</span>
          </p>
          <p className="mb-[14.4px] text-xl">
            Learn more about Secure
            <br />
            Compound Interest
          </p>
          <a href="#mpi-explained" className="text-xl">
            Learn More
          </a>
        </div>
        <div className="border border-border text-center rounded-[10px] w-[320px] min-h-[450px] bg-white pt-5 mr-2.5 flex flex-col items-center">
          <img
            src={testimonials}
            alt="testimonials"
            className="max-w-[60%] mx-auto"
          />
          <p className="mt-10 mb-[14.4px] text-2xl">
            MPI® Secure
            <br />
            Compound Interest
            <br />
            <span className="text-[28px] font-bold">Testimonials</span>
          </p>
          <p className="mb-[14.4px] text-xl">
            Hear how Secure Compoud
            <br />
            Interest has helped others
          </p>
          <a href="#testimonials" className="text-xl">
            Learn More
          </a>
        </div>
      </section>

      <section
        className="bg-greyBg p-[30px] xl:mt-5 text-dark"
        id="mpi-explained"
      >
        <h2 className="text-center font-medium text-2xl mb-3 xl:hidden">
          Who is MPI®?
        </h2>
        <div className="xl:flex xl:flex-row-reverse xl:items-center xl:justify-between">
          <video
            className="xl:w-[55%] xl:py-[40px] xl:pr-[30px]"
            controlsList="nodownload"
            poster={poster}
            preload="auto"
            controls
          >
            <source
              src="https://compoundinterest.com/wp-content/uploads/2023/07/Who-is-MPI_FinalCut.mp4"
              type="video/mp4"
            />
          </video>
          <div className="xl:text-[17.6px] xl:w-[40%] xl:pl-[100px] font-montserrat">
            <h2 className="font-medium text-[50px] mb-3 hidden xl:block">
              Who is MPI®?
            </h2>
            <p className="mt-2 md:mt-5">
              MPI® Unlimited believes every American should have the opportunity
              to participate in the benefits of a Secure Compound Interest
              Account® (SCIA™) which can include:
            </p>
            <ul className="list-disc list-inside">
              <li>Low-cost Permanent Life Insurance</li>
              <li>Guaranteed Security Against Market Losses</li>
              <li>Secure Compound Interest Growth</li>
              <li>Early Retirement Potential</li>
              <li>Up to 3x More Tax-Free Retirement Income</li>
              <li>Generational Wealth</li>
            </ul>
            <p className="mt-2">
              Since 2014, MPI® Unlimited has helped 1,000s of Americans build
              the secure retirement they expect and deserve.
            </p>
          </div>
        </div>
        <button className="w-full py-[15px] px-[30px] rounded-lg text-[15px] md:text-xl md:w-auto md:relative md:left-1/2 md:translate-x-[-50%] bg-yellow font-bold border border-black mt-5">
          <a href="https://dashboard.securedcompoundinterest.com">
            Start Today
          </a>
        </button>
      </section>

      <section className="py-[50px] xl:hidden">
        <div className="p-[25px] md:border md:rounded-[10px] md:border-x-0 md:border-border">
          <h2 className="text-center text-[40px] md:text-[50px] leading-none mb-5">
            Get Curtis Ray's Bestsellers
          </h2>
          <p className="text-[15px] md:text-xl md:px-12 lg:px-[100px]">
            Everyone Ends Up Poor! and The Lost Science of Compound Interest
            exposes the flaws of traditional retirement planning and highlights
            how downsizing has become a promoted concept in the financial
            advising community. In these books, Curtis uncovers how to avoid the
            fate of millions of Americans by increasing retirement income by up
            to 3x over traditional retirement plans.
          </p>
        </div>
        <div className="md:flex md:border-b md:border-b-border">
          <div className="p-[25px] md:w-1/2">
            <img src={book} alt="book" className="max-h-[300px] mx-auto" />
          </div>
          <div className="p-[25px] md:w-1/2">
            <img src={booktwo} alt="book" className="max-h-[300px] mx-auto" />
          </div>
        </div>
      </section>

      <section className="hidden xl:flex max-w-[1600px] mx-auto border-border border rounded-[10px] my-[50px] p-[25px]  items-center">
        <div className="w-[33%]">
          <h2 className="text-[50px] leading-none mb-5">
            Get Curtis Ray's Bestsellers
          </h2>
          <p className="text-xl">
            Everyone Ends Up Poor! and The Lost Science of Compound Interest
            exposes the flaws of traditional retirement planning and highlights
            how downsizing has become a promoted concept in the financial
            advising community. In these books, Curtis uncovers how to avoid the
            fate of millions of Americans by increasing retirement income by up
            to 3x over traditional retirement plans.
          </p>
        </div>
        <div className="w-[33%]">
          <img src={book} alt="book" className="max-h-[300px] mx-auto" />
        </div>
        <div className="w-[33%]">
          <img src={booktwo} alt="book" className="max-h-[300px] mx-auto" />
        </div>
      </section>

      <section id="testimonials">
        <h2 className="text-center text-[25px] md:text-[50px] p-2.5">
          Client Testimonials
        </h2>
        <div className="p-2.5 lg:px-[25px]">
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
            }}
            modules={[Navigation, Pagination, Autoplay]}
            navigation
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              768: {
                // navigation: true,
                // pagination: {
                //   clickable: true,
                // },
                slidesPerView: 2,
                spaceBetween: 10,
                // autoplay: {
                //   delay: 5000,
                //   pauseOnMouseEnter: true,
                // },
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/b87dtOcKcko"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/W-sYdV3SCA8"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/tyDINGetm8g"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/x1w2Hje-AvY"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/EI2MlqqWT7k"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/Nd9xhgAMRxg"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/mDlzAF50IgI"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/7MclhlwVJPw"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/IgilLuXR_J0"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/fMtgAhR50Y4"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/TF2gOpevMaA"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/x1w2Hje-AvY"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/y4fA-_Plxl0"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#/">
                <iframe
                  src="https://www.youtube.com/embed/jXfF-hcpmFs"
                  title="testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[200px] md:h-[300px]"
                />
              </a>
            </SwiperSlide>
          </Swiper>
        </div>

        <button className="w-[250px] py-[15px] px-[30px] rounded-lg text-[15px] md:text-xl relative left-1/2 translate-x-[-50%] bg-yellow font-bold border border-black my-5">
          <a href="https://dashboard.securedcompoundinterest.com">
            Start Today
          </a>
        </button>

        <div>
          <img
            src={google}
            alt="google reviews"
            className="w-[200px] mx-auto my-3"
          />
          <div className="p-2.5 lg:px-[25px] mb-5">
            <Swiper
              slidesPerView={1}
              autoplay={{
                delay: 5000,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      John Turner
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    When I signed up with Rob Duve almost two years ago, I was
                    looking for the best way to grow wealth. I had just bought
                    my first whole life policy to begin practicing infinite
                    banking and I had read books and studied infinite banking
                    for over a whole year before finally pulling the trigger.
                    Then I stumbled on a YouTube video from Curtis about
                    infinite banking. It irritated me to hear him say that MPI®
                    was infinite banking 2.0. My personality made me
                    investigate. I watched the videos, I read the books and I
                    questioned rob. It became clear that MPI® was a far superior
                    retirement plan. It had flexibility, efficiency of cost
                    compared to whole life, and more importantly, a wealth
                    building process that is accelerated by leverage within the
                    plan itself instead of taking the risk outside of the plan
                    like infinite banking. I do not know of a more secure long
                    term retirement plan than MPI®. I'm excited to have a plan
                    for my future that includes the possibility of retiring much
                    earlier than I thought possible. Thanks Curtis! Thanks Rob
                    and everyone at MPI®!! I can't wait to see how quickly I can
                    achieve my retirement income goals.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Rich Tac
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    MPI® is the only way to secure your financial future and
                    give the comfort and freedom we deserve. I am thoroughly
                    impressed with their attentiveness to my financial needs and
                    the speed in which they answered my questions and resolved
                    my concerns. I personally recommend this program to anyone
                    and everyone who wants to build their finances by
                    compounding always. Do it now, the sooner the better. You
                    won't regret it.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Travis Matthews
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    MPI® is one of the best and smartest decisions I ever made
                    for myself and my family. The team has always been extremely
                    helpful and willing to take the time to ensure I understand
                    exactly how the program works and how it is going to benefit
                    myself and my family in the long run. And to steal the
                    phrase from the MPI TEAM...ALWAYS BE COMPOUNDING!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Shaun Akin
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Myself, my wife and both of my children hold MPI® policies
                    with the intent to be financially set in retirement. The
                    MPI® concept is fantastic and a truly innovative way to
                    financially plan for retirement, tax free, while creating an
                    instant estate for future generations via death
                    benefit...also tax free. It's tremendous.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Self Directed Retirement Plans
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    MPI® has been life changing for me and my family. It has
                    changed how retirement and generating wealth will be
                    executed. Having Security, Growth, and Leverage in one
                    retirement plan is unmatched in my industry. I am grateful.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Heidi Spiegel
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    I could not be more excited about my MPI® policy!! I am so
                    pleased my money is compounding! I now look forward to
                    retirement. Jarrod and Aidan at Lifetime Income Solutions
                    were patient, polite, helpful and very knowledgeable.
                    #grateful
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Claudia Alonso
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    My husband introduced me to MPI® Unlimited and how it would
                    be more beneficial to save for retirement with an innovative
                    strategy using secure leverage in life insurance. Thanks in
                    part to the 0% floor, which provides guaranteed security
                    from market risk. Carolyn Blosil was the MPI® Certified
                    Advisor that taught us more about it and she is very
                    professional.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Rob Duve
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Understanding the power of Pay Yourself First and Always Be
                    Compounding are the most important financial concepts that
                    someone can learn. Applying these principles in a vehicle
                    like MPI® provides security and growth potential not
                    possible elsewhere and the people at MPI® Unlimited are
                    dedicated to sharing these life-changing concepts with
                    anyone who wants to learn. Knowledge is Power because as you
                    have the knowledge you are empowered to make the best
                    decision for your future. There is nothing else I've found
                    that provides me the same peace of mind that regardless of
                    market conditions, I've set myself up with a secure
                    compounding account that can lead to more spendable income
                    for myself and my wife (while also establishing generational
                    wealth for my kids). If you're ready to change the
                    trajectory of your financial future, give them a call!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Laura Sease
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    I was skeptical when my husband first told me about MPI®.
                    After meeting with our advisor Aidan Stout, I couldn't be
                    more confident and secure of our financial plan! Aidan took
                    the time to explain to us how MPI® works as an innovative
                    strategy for maximizing our retirement income as well as the
                    draw backs of the current systems available. I only wish we
                    had more money to contribute! I can't recommend Aidan
                    enough.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Jocelyne Hager
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Becoming familiar with MPI® and their retirement planning
                    has been life changing for me! I'm so happy to learn I don't
                    have to DOWNSIZE when I retire. I hope they can reach more
                    people, I will definitely be telling my loved ones about
                    MPI®!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Rebeca Blumenthal_Formerly Reaves
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Donnell is very knowledgeable and informative. Explained
                    things so that I could fully understand MPI plans and how
                    they work. He was able to answer all of my questions and
                    re-explain something if I didn't quite catch on. He's very
                    personable and makes you at ease.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Kindra Watson
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    My husband and I have had our MPI® plan going on 4 years now
                    and love it! MPI® has brought us peace of mind that allows
                    us to sleep well at night knowing our money is securely
                    compounding protected from market risk. The coolest feature
                    is the MPI® Match! Last year we qualified for the MPI® Match
                    and it was mind-blowingly awesome! The opportunity to
                    securely leverage money inside of our policies is incredibly
                    powerful and excites us about our ability to create the
                    financial future of our dreams!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Aidan Stout
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    As a young person looking ahead to retirement, I knew I had
                    time to get there. So any retirement vehicle that did not
                    secure the account during down years was an easy NO. MPI®
                    not only builds upon security, but it will create an
                    increasingly valuable tax-free income stream as well as
                    leverage securely to create higher distributions in
                    retirement, relative to the other mainstream strategies.
                    When it comes to retirement, would you rather follow the
                    crowd and the noise, or the math?
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Nash Vogeler
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Those who don't have the knowledge of Compound Interest in
                    their life, I highly encourage it! I know many friends and
                    family that downsize and retire broke. Curtis Ray is
                    brilliant and knows the secrets to getting you "The
                    Retirement You Deserve!" and becoming a millionaire!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Boris Lovato
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Fantastic stuff! I am so glad I got this valuable
                    information for free and all it took was less than 2 hours
                    for me to go through. Didn't even break a sweat. Thanks
                    Curtis and team for making this happen! Woot woot!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Osvaldo Gutierrez
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Recently getting married and becoming a first time dad made
                    me realize I had to start thinking about my future. I had
                    look into a ROTH IRA but was not pleased to find out that I
                    would only be able to take out 4%!!! With MPI® my money will
                    grow and I won't have to worry about market risk while also
                    having life insurance. Lance and Kindra were amazing walking
                    me through the process. They made sure to answer every
                    question I had about MPI® and how it compared to other
                    options. At the end of the day I feel happy and relieved
                    that I'm doing something not only for my future but for my
                    baby's future.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Niko Salgado
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    It's been almost a year since I stared with MPI, and I
                    learned about it and had reached out to Curtis on IG and had
                    all my questions answered. Before even applying, he had me
                    do some research and read a few books to understand the
                    process and I feel I was well informed. Deenise was the
                    agent that helped me complete my process and was very
                    knowledgeable and was able to answer any questions I had.
                    She periodically checks in on me and so far I've been pretty
                    happy with the outcome!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Celeste Dana
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Curtis and his MPI® team have been great to work with. We
                    took a few months to do our research and Curtis patiently
                    answered our questions and gave us the numbers until we
                    determined that this is a very sound investment in our
                    future.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Michael Stephens
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Greetings,
                    <br />I wanted to offer my assessment/review of how we feel
                    about this new chapter. You have been everything we needed
                    to put the necessary insurance and protection in place. You
                    and your team have added value and understanding to our
                    short and long term goals. We believe that with this
                    information we will leave our blueprint on future
                    generations.
                    <br />
                    Again, your and your team have been OUTSTANDING!!!!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pt-[15px] pb-[8px] px-[15px] rounded-t-[5px] border border-[#e1e8ed] flex">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-9 h-9 rounded-[50%] mr-2"
                  />
                  <div>
                    <h3 className="text-[#1c2022] font-bold text-[0.8em]">
                      Betty Ray
                    </h3>
                    <div className="flex">
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                      <FaStar fill="#f0ad4e" />
                    </div>
                  </div>
                </div>
                <div className="pb-[15px] pt-[8px] px-[15px] rounded-b-[5px] border border-t-0 border-[#e1e8ed]">
                  <p className="text-[14px] text-dark leading-5 line-clamp-4">
                    Curtis Ray and his associates are very knowledgeable,
                    informative, honest, and helpful. They have prepared my
                    husband and I (his parents), a plan that will provide us a
                    retirement for our latter years. Thanks to this excellent
                    company!
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="bg-[#d9d9d9] p-5 text-center font-montserrat text-dark">
          <h4 className="text-2xl mb-4">Location:</h4>
          <p>
            275 E Rivulon Blvd #111 <br /> Gilbert, AZ 85295 <br />
            (480) 530-5840 <br />
            curtisraympi@gmail.com
          </p>
          {/* <h4 className="text-xl mb-4 my-2">Connect With Us</h4> */}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;

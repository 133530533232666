import logo from "../assets/logo-dark.png";

const Footer = () => {
  return (
    <div className="p-5 bg-black font-montserrat">
      <img src={logo} alt="logo" className="max-h-[45px] mx-auto mb-5" />
      <p className="text-white text-xs text-center mb-5 md:text-base">
        Copyright © {new Date().getFullYear()} MPI® UNLIMITED LLC. <br /> All
        Rights Reserved.
      </p>
      <p className="text-white text-xs text-center mb-5 md:text-base">
        MPI® UNLIMITED | TERMS | PRIVACY
      </p>
    </div>
  );
};

export default Footer;
